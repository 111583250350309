import { useQuery } from '@apollo/client';
import { graphql } from '../gql';
const GET_SUBSCRIPTION_PLANS_TOKENS = graphql(
  `
    query GetSubscriptionPlansTokens {
      getSubscriptionPlans {
        id
        code
        name
        totalPlanSwiftaskToken
      }
    }
  `
);
const useGetSubscriptionPlansToken = () => {
  const { loading, data } = useQuery(GET_SUBSCRIPTION_PLANS_TOKENS);
  return { loading, data };
};
export default useGetSubscriptionPlansToken;
