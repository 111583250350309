import { useCallback, useState } from 'react';
import useGetSubscriptionContext from '../../../Context/SubscriptionContext/useGetSubscriptionContext';
import useGetSubscriptionPlansGrouped from '../../../../Hooks/useGetSubscriptionPlansGrouped';
import { SubscriptionPlanGroupType } from '../../../../gql/graphql';

const TOGGLE_DATA = [
  { value: SubscriptionPlanGroupType.Solo, intlId: 'toggle.solo' },
  { value: SubscriptionPlanGroupType.Team, intlId: 'toggle.teams' },
  { value: SubscriptionPlanGroupType.Credit, intlId: 'toggle.credit' },
] as const;


const usePricingPlansContent = () => {
  const { planCode: currentPlanCode } = useGetSubscriptionContext();
  const [groupPlan, setGroupPlan] = useState<SubscriptionPlanGroupType>(
    SubscriptionPlanGroupType.Solo
  );
  const { fetchSubscriptionPlans, loading, data } =
    useGetSubscriptionPlansGrouped();

  const filteredPlans = data?.getSubscriptionPlansGrouped ?? [];

  const handleChange = useCallback(
    (
      _event: React.MouseEvent<HTMLElement>,
      value: SubscriptionPlanGroupType | null
    ) => {
      if (value) {
        setGroupPlan(value);
        fetchSubscriptionPlans(value);
      }
    },
    []
  );

  return {
    groupPlan,
    loading,
    filteredPlans,
    handleChange,
    TOGGLE_DATA,
    currentPlanCode,
  };
};

export default usePricingPlansContent;
