import { Box, styled } from '@mui/material';
import STInputBase from '../STInputBase/STInputBase';

export const InputBaseStyled = styled(STInputBase)(({ theme }) => ({
  borderColor: theme.palette.grey[500],
  border: '1px solid',
  padding: theme.spacing(1),
}));

export const InputSelect = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey[500],
  border: '1px solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  cursor: 'pointer',
  ':hover': {
    borderColor: theme.palette.primary.main,
  },
}));
