import React from 'react';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import STGradianSlider from '../../../../../common/STGradianSlider/STGradianSlider';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import useTokenUsageSlider from './useTokenUsageSlider';

interface TokenUsageSliderProps {
  value: number;
  onChange: (event: Event, newValue: number | number[]) => void;
}

const TokenUsageSlider: React.FC<TokenUsageSliderProps> = ({
  value,
  onChange,
}) => {
  const { theme, marks, minValue, maxValue, step } = useTokenUsageSlider({
    value,
    handleTokenUsageChange: onChange,
  });

  const renderLabel = (label: string) => (
    <CustomTypography
      variant="h6"
      fontWeight="700"
      sx={{
        fontSize: ['11px', '13px'],
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {label} <AutoAwesome fontSize="small" sx={{ fontSize: '14px' }} />
    </CustomTypography>
  );

  return (
    <CustomBox
      sx={{
        width: ['90%', '70%'],
        marginInline: 'auto',
        my: theme.spacing(5),
      }}
    >
      <STGradianSlider
        value={value}
        onChange={onChange}
        min={minValue}
        max={maxValue}
        step={step}
        valueLabelDisplay="on"
        marks={
          marks?.map((mark) => ({
            ...mark,
            label: mark.label ? renderLabel(mark.label) : undefined,
          })) as any
        }
      />
    </CustomBox>
  );
};

export default TokenUsageSlider;
