import * as React from 'react';
import { Alert, AlertProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';
export interface AlertTextProps extends AlertProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  message?: any;
  id?: string;
  onClose?: () => void;
  translationValues?: any;
}

const AlertText: React.FC<AlertTextProps> = ({
  severity,
  message,
  id = 'alert.mesage',
  onClose,
  translationValues,
  ...rest
}) => {
  return (
    <Alert
      severity={severity}
      onClose={onClose}
      {...rest}
      sx={{
        whiteSpace: 'normal',
      }}
    >
      {
        <FormattedMessage
          id={id}
          defaultMessage={message}
          values={{
            ...translationValues,
          }}
        />
      }
    </Alert>
  );
};

export default AlertText;
