import { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material';
import useGetSubscriptionPlansToken from '../../../../../../Hooks/useGetSubscriptionPlansToken';

const useTokenUsageSlider = ({
  value,
  handleTokenUsageChange,
}: {
  value: number;
  handleTokenUsageChange: (event: Event, newValue: number | number[]) => void;
}) => {
  const theme = useTheme();
  const { data } = useGetSubscriptionPlansToken();

  const marks = useMemo(() => {
    if (!data?.getSubscriptionPlans) return [];

    return data.getSubscriptionPlans
      .filter((plan) => plan.totalPlanSwiftaskToken != null)
      .map((plan) => ({
        value: plan.totalPlanSwiftaskToken,
        label: plan.name,
      }));
  }, [data]);

  const { minValue, maxValue, step } = useMemo(() => {
    if (marks.length === 0)
      return { minValue: 0, maxValue: 100000, step: 1000 };
    const values = marks.map((mark) => mark.value);
    const min = Math.min(...(values as number[]));
    const max = Math.max(...(values as number[]));
    const calculatedStep = Math.round(((max - min) * 0.01) / 100) * 100;
    const step = Math.max(100, calculatedStep);

    return {
      minValue: min,
      maxValue: max,
      step,
    };
  }, [marks]);
  useEffect(() => {
    handleTokenUsageChange(null as any, minValue);
  }, [minValue]);

  return {
    theme,
    marks,
    minValue,
    maxValue,
    step,
    value,
  };
};
export default useTokenUsageSlider;
