import { Chip, ChipProps, SxProps, Theme, useTheme } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

type STGradiantChipProps = Omit<ChipProps, 'variant'> & {
  labelId: string;
  variant?: 'filled' | 'outlined';
};

const STGradiantChip: FC<STGradiantChipProps> = ({
  labelId,
  variant = 'filled',
  sx,
  disabled = false,
  ...rest
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const baseStyles: SxProps<Theme> = {
    textTransform: 'none',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    '& .MuiChip-label': {
      padding: '0 12px',
    },
  };

  const variantStyles: Record<string, SxProps<Theme>> = {
    filled: {
      background: theme.palette.customColor.gradiantColor,
      color: '#F6AE2D',
      '&:not(:disabled):hover': {
        background: theme.palette.customColor.gradiantColor,
        transform: 'translateY(-1px)',
        boxShadow: theme.palette.gradiantShadow,
      },
    },
    outlined: {
      background: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: 'inherit',
        padding: '1px',
        background: theme.palette.customColor.gradiantColor,
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        outline: 'none',
        border: 'none',
      },
      '&:not(:disabled):hover::before': {
        opacity: 0,
      },
      '&:not(.Mui-disabled):hover': {
        background: theme.palette.customColor.gradiantColor,
        transform: 'translateY(-1px)',
        outline: 'none',
        border: 'none',
        boxShadow: theme.palette.gradiantShadow,
      },
    },
  };

  const disabledStyles: SxProps<Theme> = {
    '&.Mui-disabled': {
      opacity: 0.9,
      pointerEvents: 'none',
    },
  };

  const combinedStyles = {
    ...baseStyles,
    ...variantStyles[variant],
    ...(disabled ? disabledStyles : {}),
    ...sx,
  };

  return (
    <Chip
      {...rest}
      disabled={disabled}
      sx={combinedStyles as SxProps<Theme>}
      label={intl.formatMessage({ id: labelId })}
    />
  );
};

export default STGradiantChip;
