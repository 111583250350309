import React from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps, styled
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

type ToggleType = {
  value: any;
  intlId: string;
};

interface STGradiantToggleProps
  extends Omit<ToggleButtonGroupProps, 'children'> {
  toggleData: ToggleType[];
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    '&.Mui-disabled': {
      border: 0,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'none',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.spacing(1),
  color: theme.palette.grey[200],
  '&:hover': {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 'inherit',
      padding: '1px',
      background: theme.palette.customColor.gradiantColor,
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
  },
  '&.Mui-selected': {
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 'inherit',
      padding: '1px',
      background: theme.palette.customColor.gradiantColor,
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
    fontWeight: '700',
  },
}));

const STGradiantToggle: React.FC<STGradiantToggleProps> = ({
  toggleData,
  ...rest
}) => {
  return (
    <StyledToggleButtonGroup {...rest}>
      {toggleData.map((toggle) => (
        <StyledToggleButton key={toggle.value} value={toggle.value}>
          <FormattedMessage id={toggle.intlId} />
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default STGradiantToggle;
