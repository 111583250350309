import { SxProps, Theme, alpha, keyframes } from '@mui/material';

const pulseLight = keyframes`
  0% {
    opacity: 0.6;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(2);
  }
`;

const endPulseLight = keyframes`
  0% {
    opacity: 1;
    transform: scale(2);
  }
  100% {
    opacity: 1;
    transform: scale(0.95);
  }
`;

type GradientPosition =
  | 'TOP_LEFT'
  | 'TOP_RIGHT'
  | 'BOTTOM_LEFT'
  | 'BOTTOM_RIGHT';

const getGradientPosition = (pos: GradientPosition) => {
  switch (pos) {
    case 'TOP_LEFT':
      return { top: 0, left: 0, borderRadius: '0 0 33.18% 0' };
    case 'TOP_RIGHT':
      return { top: 0, right: 0, borderRadius: '0 0 0 33.18%' };
    case 'BOTTOM_LEFT':
      return { bottom: 0, left: 0, borderRadius: '0 33.18% 0 0' };
    case 'BOTTOM_RIGHT':
    default:
      return { bottom: 0, right: 0, borderRadius: '33.18% 0 0 0' };
  }
};
export const gradientBoxStyles = (
  theme: Theme,
  position: GradientPosition = 'BOTTOM_RIGHT'
) => {
  const positionStyles = getGradientPosition(position);

  return {
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    background:
      theme.palette.mode === 'dark'
        ? `radial-gradient(33.18% 27.56% at ${
            position === 'TOP_LEFT' || position === 'BOTTOM_LEFT'
              ? '0% '
              : '100% '
          }${
            position === 'TOP_LEFT' || position === 'TOP_RIGHT' ? '0%' : '100%'
          }, ${alpha(theme.palette.info.main, 0.2)} 0%, ${
            theme.palette.background.default
          } 100%)`
        : '#f5f6fa',
    position: 'relative',
    padding: theme.spacing(2),
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 'inherit',
      padding: '1px',
      background:
        'linear-gradient(180deg, rgba(16, 152, 247, 0.2) 0%, rgba(255, 110, 114, 0.2) 50%, rgba(246, 174, 45, 0.2) 100%)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      ...positionStyles,
      width: '33.18%',
      height: '27.56%',
      background:
        theme.palette.mode === 'dark'
          ? `radial-gradient(circle at ${
              position === 'TOP_LEFT' || position === 'BOTTOM_LEFT'
                ? 'left'
                : 'right'
            } ${
              position === 'TOP_LEFT' || position === 'TOP_RIGHT'
                ? 'top'
                : 'bottom'
            }, ${alpha(theme.palette.info.dark, 0.1)} 0%, ${alpha(
              theme.palette.info.dark,
              0
            )} 60%)`
          : 'transparent',
      filter: 'blur(10px)',
      transformOrigin: `${
        position === 'TOP_LEFT' || position === 'BOTTOM_LEFT' ? 'left' : 'right'
      } ${
        position === 'TOP_LEFT' || position === 'TOP_RIGHT' ? 'top' : 'bottom'
      }`,
      opacity: 1,
      transform: 'scale(0.95)',
    },
  };
};

export const gradientBoxStyleWithHoverAnimation = (
  theme: Theme,
  position: GradientPosition = 'BOTTOM_RIGHT'
) => {
  const positionStyles = getGradientPosition(position);

  return {
    ...(gradientBoxStyles(theme, position) as SxProps),
    '&:hover::before': {
      opacity: 1,
      animation: 'rotateGradient 2.5s infinite',
      background: theme.palette.customColor.gradiantColor,
    },
    '&:hover::after': {
      ...positionStyles,
      background:
        theme.palette.mode === 'dark'
          ? `radial-gradient(circle at ${
              position === 'TOP_LEFT' || position === 'BOTTOM_LEFT'
                ? 'left'
                : 'right'
            } ${
              position === 'TOP_LEFT' || position === 'TOP_RIGHT'
                ? 'top'
                : 'bottom'
            }, ${alpha(theme.palette.info.main, 0.25)} 0%, ${alpha(
              theme.palette.info.main,
              0
            )} 60%)`
          : 'transparent',
      animation: `${pulseLight} 1s ease-in-out forwards`,
    },
    '&:not(:hover)::after': {
      ...positionStyles,
      background:
        theme.palette.mode === 'dark'
          ? `radial-gradient(circle at ${
              position === 'TOP_LEFT' || position === 'BOTTOM_LEFT'
                ? 'left'
                : 'right'
            } ${
              position === 'TOP_LEFT' || position === 'TOP_RIGHT'
                ? 'top'
                : 'bottom'
            }, ${alpha(theme.palette.info.main, 0.25)} 0%, ${alpha(
              theme.palette.info.main,
              0
            )} 60%)`
          : 'transparent',
      animation: `${endPulseLight} 1s ease-in-out forwards`,
    },
    '@keyframes rotateGradient': {
      '0%': { transform: 'rotate(0deg)' },
      '50%': { transform: 'rotate(1deg)' },
      '100%': { transform: 'rotate(0deg)' },
    },
  };
};
