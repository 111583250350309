import { SxProps, Theme, useTheme } from '@mui/material';
import { FC } from 'react';
import CustomButton, { CustomButtonProps } from '../CustomButton/CustomButton';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';

interface STGradiantButtonProps extends CustomButtonProps  {
  labelId: string;
  disabled?: boolean;
};

const STGradiantButton: FC<STGradiantButtonProps> = ({
  labelId,
  variant = 'text',
  disabled = false,
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const baseStyles: SxProps<Theme> = {
    textTransform: 'none',
    transition: 'all 0.3s ease',
    outline: 'none',
    border: 'none',
    opacity: disabled ? 0.5 : 1,
  };

  const variantStyles: Record<string, SxProps<Theme>> = {
    contained: {
      background: theme.palette.customColor.gradiantColor,
      '& .MuiTypography-root': {
        color: '#FFFFFF',
      },
      '&:hover': {
        background: theme.palette.customColor.gradiantColor,
        boxShadow: theme.palette.gradiantShadow,
      },
    },
    outlined: {
      background: 'transparent',
      position: 'relative',
      color: theme.palette.common.black,
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: 'inherit',
        padding: '1px',
        background: theme.palette.customColor.gradiantColor,
        WebkitMask:
          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        outline: 'none',
        border: 'none',
      },
      '&:hover::before': {
        opacity: 0,
      },
      '&:hover': {
        background: theme.palette.customColor.gradiantColor,
        outline: 'none',
        color: '#FFFFFF',
        '& .MuiTypography-root': {
          color: '#FFFFFF',
        },
        border: 'none',
        boxShadow: theme.palette.gradiantShadow,
      },
    },
    text: {
      background: 'transparent',
      color: '#F6AE2D',
      backgroundImage: theme.palette.customColor.inversGradiantColor,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  };

  const combinedStyles = {
    ...baseStyles,
    ...variantStyles[variant],
    ...sx,
  };

  return (
    <CustomButton
      {...rest}
      disabled={disabled}
      variant={variant}
      sx={combinedStyles as SxProps<Theme>}
    >
      <CustomTypography sx={{ fontWeight: 700 }}>
        <FormattedMessage id={labelId} />
      </CustomTypography>
    </CustomButton>
  );
};

export default STGradiantButton;
