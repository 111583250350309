import { FormattedMessage } from 'react-intl';
import { ShoppingCart } from '@mui/icons-material';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import PurchaseCreditDialog from './PurchaseCreditDialog/PurchaseCreditDialog';
import React from 'react';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { ButtonProps, useTheme } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomButton from '../../../common/CustomButton/CustomButton';

interface PurchaseCreditButtonProps extends ButtonProps {
  buttonHeight?: number;
  buttonWidth?: number;
  isLinkButton?: boolean;
}

const PurchaseCreditButton: React.FC<PurchaseCreditButtonProps> = ({
  buttonHeight,
  buttonWidth,
  isLinkButton = false,
  ...rest
}) => {
  // Open state
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    Mixpanel.track('Click on open purchase credit dialog', {});
    setOpen(true);
  };

  return (
    <>
      {isLinkButton ? (
        <CustomBox
          onClick={onOpen}
          sx={{
            display: 'flex',
            width: '65%',
            gap: 1,
            cursor: 'pointer',
            p: 0.5,
            borderRadius: '4px',
            border: `1px solid ${theme.palette.divider}`,
            alignItems: 'center',
          }}
        >
          <CustomTypography
            sx={{
              color: theme.palette.mode === 'dark' ? '#fff' : 'blue',
              fontSize: '12px',
              alignItems: 'center',
            }}
          >
            <FormattedMessage
              id="button.purchaseCredit"
              defaultMessage="Get Credit"
            />
          </CustomTypography>
        </CustomBox>
      ) : (
        <CustomButton
          variant="contained"
          intlId="button.purchaseCredit"
          fullWidth={buttonWidth ? false : true}
          sx={{
            height: buttonHeight ? buttonHeight : 32,
            width: buttonWidth ? buttonWidth : '100%',
          }}
          {...rest}
          onClick={onOpen}
          startIcon={<ShoppingCart />}
        />
      )}
      <PurchaseCreditDialog open={open} onClose={onClose} />
    </>
  );
};

export default PurchaseCreditButton;
