import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, useTheme } from '@mui/material';

import CustomBox from '../../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import { SubscriptionPlanType } from '../../PricingPlansContent';
import { PlanType } from '../usePricingPlanItem';
import { SubscriptionPlanGroupType } from '../../../../../../gql/graphql';

interface PriceDisplayProps {
  plan: SubscriptionPlanType;
  groupPlan: SubscriptionPlanGroupType;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ plan, groupPlan }) => {
  const theme = useTheme();

  const renderMonthlyPrice = () => (
    <span>
      {groupPlan !== SubscriptionPlanGroupType.Team && (
        <span style={{ color: theme.palette.secondary.main }}>
          <FormattedMessage
            id="number.user.month"
            values={{ numberUser: '1' }}
          />
        </span>
      )}
      <FormattedMessage id="price.per.month" />
      <br />
      <span style={{ color: theme.palette.grey[200] }}>
        <FormattedMessage id="price.per.year.indication" />
      </span>
    </span>
  );

  const renderAnnualPrice = () => (
    <FormattedMessage
      id="price.per.annual"
      values={{ price: plan.code === PlanType.FREE ? 0 : plan.price }}
    />
  );

  return (
    <CustomBox
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      gap={0.5}
    >
      <CustomTypography
        component="div"
        variant="h6"
        sx={{
          fontWeight: '400',
          paddingLeft: 1,
          lineHeight: '12px',
          fontSize: '12px',
        }}
      >
        {renderMonthlyPrice()}
      </CustomTypography>
      <Divider
        sx={{
          width: 50,
          ml: 1,
          border: `1px solid ${theme.palette.grey[200]}`,
        }}
      />
      <CustomTypography
        component="div"
        variant="h6"
        sx={{
          fontWeight: '400',
          paddingLeft: 1,
          lineHeight: '12px',
          fontSize: '12px',
        }}
      >
        {renderAnnualPrice()}
      </CustomTypography>
    </CustomBox>
  );
};

export default PriceDisplay;
