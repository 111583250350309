import { FC } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';
import CustomTemperature from './CustomTemperature/CustomTemperature';

import { BotDetailsType } from '../../../types/BotDetails';
import BotCreditAndUpgrade from './BotCreditAndupgrade';
import BotAttributes from './BotAttributes/BotAttributes';
import BaseBotDetail from './BaseBotDetails/BaseBotDetail';

import UserAvatar from '../UserAvatar/UserAvatar';
import STCreatorProfile from '../STCreatorProfile/STCreatorProfile';
import FavoriteBotMark from './FavoriteBotMark/FavoriteBotMark';

interface IProps {
  bot: BotDetailsType;
  displayCreditInfo?: boolean;
  currentUserEmail: string;
  isBotConfigMode?: boolean;
  hideUpgradeButton?: boolean;
  baseBot?: BotDetailsType;
  isBaseBotTypetext?: boolean;
  isDisplayBotAttributes?: boolean;
}

const BotDetails: FC<IProps> = ({
  bot,
  displayCreditInfo,
  currentUserEmail,
  hideUpgradeButton,
  isDisplayBotAttributes,
  baseBot,
  isBaseBotTypetext = false,
}) => {
  return (
    <>
      <CustomBox
        sx={{
          backgroundColor: (theme) => theme.palette.customColor.hoverColor,
          display: 'flex',
          width: '100%',
          padding: (theme) => theme.spacing(2),
          borderRadius: (theme) => theme.spacing(1),
        }}
      >
        <CustomBox
          sx={{
            marginRight: (theme) => theme.spacing(2),
          }}
        >
          <UserAvatar
            sx={{
              width: '24px',
              height: '24px',
            }}
            userProfil={bot?.user?.profilePicture}
          />
        </CustomBox>
        <CustomBox
          sx={{
            flexGrow: 1,
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <CustomBox>
            <CustomBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
              width={'100%'}
            >
              <CustomTypography variant="h4" fontWeight="bold">
                {bot?.name}
              </CustomTypography>
              <CustomBox display="flex" flexDirection="row" alignItems="center">
                <FavoriteBotMark botId={bot?.id} isFavorite={bot?.isFavorite} />
                {bot?.enableUserCustomization ? (
                  <CustomTemperature botId={bot?.id} />
                ) : null}
              </CustomBox>
            </CustomBox>
            <CustomTypography variant="body1">
              {bot?.description}
            </CustomTypography>
            <CustomBox display="flex" justifyContent="space-between">
              {baseBot && (
                <BaseBotDetail
                  isBaseBotTypetext={isBaseBotTypetext}
                  name={baseBot?.name ?? ''}
                  profileUrl={baseBot?.user?.profilePicture ?? ''}
                />
              )}
              <STCreatorProfile creator={bot?.createdBy} />
            </CustomBox>
          </CustomBox>
          {isDisplayBotAttributes && (
          <CustomBox mt={1}>
            <BotAttributes botId={bot?.id} />
          </CustomBox>
        )}
        </CustomBox>
      </CustomBox>
      <CustomBox mt={(theme) => theme.spacing(1)}>
        {!hideUpgradeButton && (
          <CustomBox mt={1}>
            <BotCreditAndUpgrade
              bot={bot}
              displayCreditInfo={displayCreditInfo}
              currentUserEmail={currentUserEmail}
            />
          </CustomBox>
        )}
      </CustomBox>
    </>
  );
};

export default BotDetails;
