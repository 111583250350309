import React from 'react';
import { Slider, SliderProps, useTheme } from '@mui/material';
import { formatNumber } from '../../../utils/numberUtil';
import config from '../../../config/config';
interface props extends SliderProps {}
const STGradianSlider = ({ ...rest }: props) => {
  const theme = useTheme();
  return (
    <Slider
      {...rest}
      valueLabelFormat={(value) => formatNumber(value)}
      sx={{
        '& .MuiSlider-track': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.customColor.hoverColor
              : '#24262C',
          height: 16,
          border: 'none',
        },
        '& .MuiSlider-thumb': {
          height: 20,
          width: 20,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.info.main : '#24262C',
          zIndex: config.zIndex.NIVEAU_2,
          '&:hover': {
            boxShadow: 'inherit',
          },
        },
        '& .MuiSlider-mark': {
          backgroundColor: theme.palette.grey[200],
          height: '10px',
          borderRadius: '5px',
          width: '4px',
          zIndex: config.zIndex.NIVEAU_1,
          '&:hover': {
            backgroundColor: '#F6AE2D',
            width: '5px',
            height: '13px',
            transition: 'all 0.5s ease-in-out',
          },
        },
        '& .MuiSlider-rail': {
          paddingX: 2,
          background: 'transparent',
          position: 'relative',
          height: 16,
          zIndex: config.zIndex.NIVEAU_1,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            zIndex: config.zIndex.NIVEAU_1,
            borderRadius: 'inherit',
            padding: '1px',
            background: theme.palette.customColor.gradiantColor,
            WebkitMask:
              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            outline: 'none',
            border: 'none',
          },
        },
        '& .MuiSlider-valueLabel': {
          backgroundColor: 'transparent',
        },
        '& .MuiSlider-valueLabelCircle': {
          position: 'relative',
          color: theme.palette.text.primary,
          paddingX: '24px',
          paddingY: '8px',
          borderRadius: '8px',
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: 'inherit',
            padding: '2px',
            background: theme.palette.customColor.gradiantColor,
            WebkitMask:
              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-4px',
            left: '50%',
            transform: 'translateX(-50%) rotate(-45deg)',
            width: '10px',
            height: '10px',
            background: theme.palette.customColor.gradiantColor,
            clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
          },
        },
      }}
    />
  );
};

export default STGradianSlider;
