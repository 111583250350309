import { useMutation } from '@apollo/client';
import { graphql } from '../gql';
import useConfirmDialog from './useConfirmDialog';
import { GET_BOTS_BY_TAG } from './useGetBotsByTag';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
const DELETE_CUSTOM_BOT = graphql(`
  mutation DeleteCustomBot($botId: Float!) {
    deleteCustomBot(botId: $botId) {
      baseBot {
        id
        name
      }
      id
      name
      removed
      systemPrompt
      user {
        id
        profilePicture
      }
      description
      greetingMessage
    }
  }
`);
const useDeleteCustomBot = () => {
  const { displayConfirmDialog } = useConfirmDialog();
  const [deleteCustomBot, { loading }] = useMutation(DELETE_CUSTOM_BOT);
  const { displayMessage } = useDisplayMessage();
  const handleDeleteCustomBot = (botId: number) => {
    displayConfirmDialog({
      message: 'view.delete.title.dialog',
      onConfirm: () => {
        deleteCustomBot({
          variables: {
            botId,
          },
          update(cache, { data }) {
            if (!data) {
              return;
            }
            const existingBots = cache.readQuery({
              query: GET_BOTS_BY_TAG,
              variables: {
                tagSlug: 'mybots',
                limit: 1000,
              },
            });
            const filteredBots: any = existingBots?.getSearchBots.filter(
              (bot) => bot.id !== data.deleteCustomBot.id
            );
            cache.writeQuery({
              query: GET_BOTS_BY_TAG,
              variables: {
                tagSlug: 'mybots',
                limit: 1000,
              },
              data: {
                getSearchBots: [...filteredBots],
              },
            });
            displayMessage({
              message: 'bot.delete.success',
              type: 'success',
            });
          },
        });
      },
    });
  };
  return { handleDeleteCustomBot, loading };
};
export default useDeleteCustomBot;
