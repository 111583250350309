import useSwiftaskTokenPrices from '../../Chat/hooks/useSwiftaskTokenPrices';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import CustomBox from '../../common/CustomBox/CustomBox';
import CustomBagde from '../../common/CustomBagde/CustomBagde';
import CustomButton from '../../common/CustomButton/CustomButton';
import Loading from '../../common/Loading/Loading';
import { formatNumber } from '../../../utils/numberUtil';
import { FormattedMessage } from 'react-intl';
import { Divider, SxProps, useTheme } from '@mui/material';
import STGradiantButton from '../../common/STGradiantButton/STGradiantButton';
import STGradiantChip from '../../common/STGradiantChip/STGradiantChip';
import { gradientBoxStyleWithHoverAnimation } from '../../../Styles/GradianStyle';

const SwiftaskCreditPurshase = () => {
  const { loading, swiftaskTokenPrices, onClickPurchase } =
    useSwiftaskTokenPrices();
  const theme = useTheme();
  if (loading) {
    return (
      <CustomBox>
        <Loading />
      </CustomBox>
    );
  }

  return (
    <Grid container spacing={1}>
      {swiftaskTokenPrices.map(
        (pack: (typeof swiftaskTokenPrices)[0], index: number) => (
          <Grid item xs={12} sm={6} md={4} key={pack.id}>
            <CardContent
              sx={{
                ...(gradientBoxStyleWithHoverAnimation(
                  theme,
                  index === 0
                    ? 'BOTTOM_LEFT'
                    : index === 1
                    ? 'TOP_LEFT'
                    : 'BOTTOM_RIGHT'
                ) as SxProps),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 250,
              }}
            >
              <CustomBox
                sx={{
                  display: 'flex',
                  width: '100%',
                  opacity: pack.percentageGain ? 1 : 0,
                }}
              >
                <STGradiantChip
                  disabled
                  variant={'outlined'}
                  labelId={`+${pack.percentageGain} %`}
                  sx={{
                    marginInline: 'auto',
                    fontSize: '12px',
                    fontWeight: '700',
                    height: '17px',
                    width: 80,
                    textTransform: 'uppercase',
                  }}
                />
              </CustomBox>

              <CustomBox mt={2}>
                <CustomTypography
                  variant="h1"
                  component="div"
                  sx={{
                    fontSize: 36,
                    fontWeight: '900',
                  }}
                >
                  {formatNumber(pack.quantity)}
                </CustomTypography>
              </CustomBox>
              <CustomBox mt={1}>
                <CustomTypography
                  variant="h4"
                  component="div"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: theme.palette.grey[200],
                  }}
                >
                  <FormattedMessage id="credit.purchase.price" />
                </CustomTypography>
              </CustomBox>
              <Divider
                sx={{
                  width: 50,
                  ml: 1,
                  border: `1px solid ${theme.palette.grey[200]}`,
                }}
              />
              <CustomBox mt={1}>
                <CustomTypography variant="h2" component="div">
                  €{pack.price}
                </CustomTypography>
              </CustomBox>

              <STGradiantButton
                sx={{
                  width: '100%',
                  zIndex: 999,
                  bgcolor: pack.btnColor,
                }}
                variant={index === 1 ? 'contained' : 'outlined'}
                labelId={pack.buttonText || 'Purchase'}
                onClick={() => onClickPurchase(pack.id)}
                isLoading={loading}
                disabled={loading}
              />
            </CardContent>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default SwiftaskCreditPurshase;
