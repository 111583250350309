import { FC } from 'react';
import { BotDetailsType } from '../../../types/BotDetails';
import CustomBox from '../CustomBox/CustomBox';
import BotLimitReachedCTA from '../../Chat/BodyChat/Message/BotLimitReachedCTA/BotLimitReachedCTA';
import AlertText from '../AlertText/AlertText';

interface IProps {
  bot: BotDetailsType;
  displayCreditInfo?: boolean;
  currentUserEmail?: string;
}

const BotCreditAndUpgrade: FC<IProps> = ({ bot }) => {
  const remainingExtraCredit =
    bot?.currentUserCredit?.remainingExtraCredit || 0;
  const noExtracredit = remainingExtraCredit <= 0;
  const shouldDisplayUpgradeCTA =
    noExtracredit && !bot?.isBotPartOfSubscription;

  return (
    <CustomBox>
      <CustomBox mb={(theme) => theme.spacing(1)}>
        <AlertText
          variant="outlined"
          sx={{
            borderRadius: (theme) => theme.spacing(1),
            justifyContent: 'center',
            display: 'flex',
          }}
          severity={'warning'}
          id="info.upgrade.plan"
        />
      </CustomBox>
      <CustomBox
        sx={{
          // place the content to the right
          display: 'flex',
          backgroundColor: (theme) => theme.palette.customColor.hoverColor,
          borderRadius: (theme) => theme.spacing(1),
        }}
      >
        {shouldDisplayUpgradeCTA ? (
        <CustomBox
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          mt={(theme) => theme.spacing(1)}
          mb={1}
        >
          <BotLimitReachedCTA botId={bot?.id} isDisplayDescription={true} />
        </CustomBox>
        ) : null} 
      </CustomBox>
    </CustomBox>
  );
};

export default BotCreditAndUpgrade;
