import React, { useEffect } from 'react';
import { InputSelect } from './STAuthConfig.style';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import STAuthConfigDialog from './STAuthConfig.dialog';
import TuneIcon from '@mui/icons-material/Tune';
import CustomTypography from '../CustomTypography/CustomTypography';
import { useIntl } from 'react-intl';

export interface IValue {
  type: 'none' | 'apikey';
  apikey?: string;
  typeLabel?: 'Basic' | 'Bearer' | 'custom';
  customLabel?: string;
}

interface IProps {
  onChange: (value: IValue) => void;
  value: IValue;
}

const STAuthConfig: React.FC<IProps> = ({ onChange, value }) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const handleclose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (value?.type === undefined) {
      onChange({
        type: 'none',
      });
    }
  }, [value?.type]);

  return (
    <>
      <InputSelect onClick={handleOpen}>
        <CustomTypography>
          {intl.formatMessage({
            id: value?.type
              ? 'stauth.config.type.' + value?.type
              : 'stauth.config.type.none',
          })}
        </CustomTypography>

        <CustomIconButton>
          <TuneIcon />
        </CustomIconButton>
      </InputSelect>

      {open ? (
        <STAuthConfigDialog
          open={open}
          handleclose={handleclose}
          value={value}
          onChange={onChange}
        />
      ) : null}
    </>
  );
};

export default React.memo(STAuthConfig);
