import { useMemo, useState } from 'react';
import useGetBotsUseCaseLabel from '../../../../../Hooks/useGetBotsUseCaseLabel';
import { STTableProps } from '../../../../common/STTable/STTable';
import { formatNumber } from '../../../../../utils/numberUtil';

const useTokenUsage = () => {
  const [tokenUsage, setTokenUsage] = useState(200000);
  const { data } = useGetBotsUseCaseLabel();

  const handleTokenUsageChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setTokenUsage(newValue as number);
  };
  const columns: STTableProps['columns'] = [
    { title: 'usage.case', width: '50%' },
    { title: 'volume', width: '50%' },
  ];

  const rows: STTableProps['rows'] = useMemo(() => {
    if (!data?.botsUseCaseLabel) return [];

    return data.botsUseCaseLabel.map((bot) => [
      { value: bot.useCaseLabel },
      {
        value: `${formatNumber(
          tokenUsage / (bot.swiftaskTokenCoef as number)
        )} ${bot.quantityLabel}`,
      },
    ]);
  }, [data, tokenUsage]);
  return { tokenUsage, handleTokenUsageChange, columns, rows };
};
export default useTokenUsage;
