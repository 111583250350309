import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { Mixpanel } from '../mixpanel/mixpanel';

const GET_SUBSCRIPTION_URL = graphql(
  `
    query GetSubscriptionUpdateUrl(
      $newPlanCode: String!
      $hasFreeTrialPeriod: Boolean
    ) {
      getSubscriptionUpdateUrl(
        newPlanCode: $newPlanCode
        hasFreeTrialPeriod: $hasFreeTrialPeriod
      )
    }
  `
);

const useGetSubsciptionUrl = () => {
  const { displayMessage } = useDisplayMessage();
  const [getSubscriptionUpdateUrl, { loading: loadingUpdateUrl }] =
    useLazyQuery(GET_SUBSCRIPTION_URL);

  const startUpgrade = ({
    hasFreeTrialPeriod,
    code,
  }: {
    code: string;
    hasFreeTrialPeriod: boolean;
  }) => {
    Mixpanel.track('Click on upgrade workspace button', {});
    getSubscriptionUpdateUrl({
      variables: { newPlanCode: code, hasFreeTrialPeriod },
    })
      .then((result) => {
        const subscriptionUrl = result.data?.getSubscriptionUpdateUrl;
        if (!subscriptionUrl) {
          displayMessage({
            message: 'subscription.upgrade.geturl.error',
            type: 'error',
          });
          return;
        }
        Mixpanel.track('Redirect to stripe subscription page', {});
        window.location.assign(subscriptionUrl);
      })
      .catch((err) => {
        displayMessage({
          message: 'subscription.upgrade.geturl.error',
          type: 'error',
        });
      });
  };

  return {
    startUpgrade,
    loadingUpdateUrl,
  };
};

export default useGetSubsciptionUrl;
