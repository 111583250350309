import { AutoAwesome } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { SUBSCRIPTION_LEVEL } from '../../../constants/subscription';
import { graphql } from '../../../gql';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import CustomBox from '../../common/CustomBox/CustomBox';
import STSubscriptionPlanBadge from '../../common/STSubscriptionPlanBadge/STSubscriptionPlanBadge';
import { SubscriptionContext } from '../../Context/SubscriptionContext/SubscriptionContext';
import useGetSubsciptionUrl from '../../../Hooks/useGetSubsciptionUrl';
import PricingPlansContent from '../../UpgradeButton/PricingPlansDialog/PricingPlansDialog';
import STGradiantButton from '../../common/STGradiantButton/STGradiantButton';

// Query getCustomerPortalUrl
const GET_CUSTOMER_PORTAL_URL = graphql(
  `
    query getCustomerPortalUrl {
      getCustomerPortalUrl
    }
  `
);

interface IProps {
  alwaysDisplayUpgradeButton?: boolean;
  buttonHeight?: number;
  buttonWidth?: number;
}

const UpgradeWorkspace = ({
  alwaysDisplayUpgradeButton,
  buttonHeight,
  buttonWidth,
}: IProps) => {
  const { loadingUpdateUrl } = useGetSubsciptionUrl();
  const [openDialogUpgrade, setOpenDialogUpgrade] = useState(false);

  const onClick = () => {
    Mixpanel.track('Click on upgrade workspace button', {});
    setOpenDialogUpgrade(true);
  };

  const handleClosePricingDialog = () => setOpenDialogUpgrade(false);

  const currentSub = useContext(SubscriptionContext);

  // Display upgrade button only if user is in free plan
  if (
    currentSub.currentUserSubscription?.code &&
    currentSub.currentUserSubscription?.code !== SUBSCRIPTION_LEVEL.FREE &&
    !alwaysDisplayUpgradeButton
  )
    return (
      <CustomBox mr={4}>
        <STSubscriptionPlanBadge
          plan={currentSub.currentUserSubscription?.code || ''}
          onClick={onClick}
        />
      </CustomBox>
    );

  return (
    <>
      <STGradiantButton
        labelId="workspace.upgrade.button"
        intlValues={{
          plan: 'Pro',
        }}
        variant="contained"
        onClick={onClick}
        sx={{
          height: buttonHeight || 28,
          width: buttonWidth || 'auto',
        }}
        startIcon={<AutoAwesome />}
        isLoading={loadingUpdateUrl}
        disabled={loadingUpdateUrl}
      />
      <PricingPlansContent
        isPricingDialogOpen={openDialogUpgrade}
        handleClosePricingDialog={handleClosePricingDialog}
      />
    </>
  );
};

export default UpgradeWorkspace;
