import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import { useTheme } from '@mui/material';
import TokenUsageSlider from './TokenUsageSlider/TokenUsageSlider';
import TokenUsageTable from './TokenUsageTable/TokenUsageTable';
import useTokenUsage from './useTokenUsage';

const TokenUsage = () => {
  const theme = useTheme();
  const { tokenUsage, handleTokenUsageChange, columns, rows } = useTokenUsage();
  return (
    <CustomBox
      display={'flex'}
      mt={3}
      flexDirection={'column'}
      justifyContent={'center'}
      gap={1}
      width={'100%'}
    >
      <CustomTypography fontWeight={'700'} variant="h4" textAlign={'center'}>
        <FormattedMessage id="token.usage.title" />
      </CustomTypography>
      <CustomTypography
        variant="h6"
        fontWeight={'400'}
        textAlign={'center'}
        sx={{
          color: theme.palette.grey[200],
        }}
      >
        <FormattedMessage id="token.usage.description" />
      </CustomTypography>
      <TokenUsageSlider value={tokenUsage} onChange={handleTokenUsageChange} />
      <TokenUsageTable columns={columns} rows={rows} />
    </CustomBox>
  );
};
export default TokenUsage;
