import React, { ChangeEvent, useEffect } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import STRadioGroup from '../STRadioGroup/STRadioGroup';
import { InputBaseStyled } from './STAuthConfig.style';
import CustomBox from '../CustomBox/CustomBox';
import CustomDialog from '../CustomDialog/CustomDialog';
import CustomButton from '../CustomButton/CustomButton';
import { IValue } from './STAuthConfig';

interface IProps {
  onChange: (value: IValue) => void;
  value: IValue;
  open: boolean;
  handleclose: () => void;
}

const STAuthConfigDialog: React.FC<IProps> = ({
  onChange,
  value,
  handleclose,
  open,
}) => {
  const [currentValue, setCurrentValue] = React.useState<IValue>(value);

  const intl = useIntl();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue({
      ...currentValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeType = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as 'none' | 'apikey';
    if (value === 'apikey') {
      setCurrentValue({
        type: value,
        typeLabel: 'Basic',
        customLabel: '',
        apikey: '',
      });
      return;
    }
    setCurrentValue({
      type: value,
    });
  };

  const onCanceled = () => {
    setCurrentValue(value);
    handleclose();
  };

  const onValidate = () => {
    onChange(currentValue);
    handleclose();
  };

  const disableValidate = () => {
    if (currentValue?.type !== 'apikey') {
      return false;
    }

    const { typeLabel, apikey, customLabel } = currentValue;

    return (typeLabel === 'custom' && customLabel === '') || apikey === '';
  };
  return (
    <CustomDialog
      open={open}
      handleClose={handleclose}
      title={intl.formatMessage({
        id: 'stauth.dialog.title',
      })}
      contentChildren={
        <CustomBox display="flex" flexDirection="column" mt={2}>
          <STRadioGroup
            value={currentValue?.type || 'none'}
            onChange={handleChangeType}
            name="type"
            title="stauth.config.type.title"
            options={[
              {
                label: intl.formatMessage({
                  id: 'stauth.config.type.none',
                  defaultMessage: 'None',
                }),
                value: 'none',
              },
              {
                label: intl.formatMessage({
                  id: 'stauth.config.type.apikey',
                  defaultMessage: 'Api key',
                }),
                value: 'apikey',
              },
            ]}
            row={true}
          />
          {currentValue.type === 'apikey' ? (
            <>
              <InputBaseStyled
                name="apikey"
                value={currentValue?.apikey || ''}
                onChange={handleChange}
                placeholder={intl.formatMessage({
                  id: 'stauth.config.apikey.placeholder',
                  defaultMessage: 'Enter your api key',
                })}
              />
              <CustomBox mt={1}>
                <STRadioGroup
                  value={currentValue.typeLabel || ''}
                  onChange={handleChange}
                  name="typeLabel"
                  title="stauth.config.typeLabel.title"
                  options={[
                    {
                      label: 'Basic',
                      value: 'Basic',
                    },
                    {
                      label: 'Bearer',
                      value: 'Bearer',
                    },
                    {
                      label: intl.formatMessage({
                        id: 'stauth.config.typeLabel.custom',
                        defaultMessage: 'Custom',
                      }),
                      value: 'custom',
                    },
                  ]}
                  row={true}
                />
              </CustomBox>

              {currentValue.typeLabel === 'custom' ? (
                <InputBaseStyled
                  name="customLabel"
                  onChange={handleChange}
                  value={currentValue?.customLabel || ''}
                  placeholder="X-api-key"
                />
              ) : null}
            </>
          ) : null}
        </CustomBox>
      }
      actionChildren={
        <CustomBox display="flex" alignItems="center" gap={1}>
          <CustomButton
            intlId="cancel"
            onClick={onCanceled}
            variant="outlined"
          />
          <CustomButton
            intlId="stauth.config.save"
            onClick={onValidate}
            variant="contained"
            color="primary"
            disabled={disableValidate()}
          />
        </CustomBox>
      }
    />
  );
};

export default React.memo(STAuthConfigDialog);
