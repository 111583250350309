import React from 'react';
import { Skeleton, Card, CardContent } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';

const PricingLoader: React.FC = () => {
  return (
    <CustomBox display="flex" justifyContent="center" gap={1}>
      {[...Array(3)].map((_, index) => (
        <Card key={index} sx={{ minWidth: 300 }}>
          <CardContent>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="rectangular" height={100} sx={{ my: 2 }} />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="rounded" height={40} sx={{ mt: 2 }} />
          </CardContent>
        </Card>
      ))}
    </CustomBox>
  );
};

export default PricingLoader;
