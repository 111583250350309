import { useMemo, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import useGetSubscriptionUrl from '../../../../../Hooks/useGetSubsciptionUrl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import STChip from '../../../../common/STChip/STChip';
import { SubscriptionPlanType } from '../PricingPlansContent';

interface UsePricingPlanItemProps {
  plan: SubscriptionPlanType;
  currentPlanCode: string;
}
export enum PlanType {
  FREE = 'FREE',
  PRO = 'PRO',
  TEAM_STARTER = 'TEAM_STARTER',
  TEAM_STARTER_ANNUAL = 'TEAM_STARTER_ANNUAL',
  TEAM_GROWTH = 'TEAM_GROWTH',
  TEAM_GROWTH_ANNUAL = 'TEAM_GROWTH_ANNUAL',
}

const usePricingPlanItem = ({
  plan,
  currentPlanCode,
}: UsePricingPlanItemProps) => {
  const { startUpgrade, loadingUpdateUrl } = useGetSubscriptionUrl();
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isPro = useMemo(() => plan.code === 'PRO', [plan.code]);
  
  const isTeamStarterPlan = useMemo(
    () =>
      plan.code === PlanType.TEAM_STARTER ||
      plan.annualCode === PlanType.TEAM_STARTER_ANNUAL,
    [plan.code]
  );
  const isFreePlan = useMemo(() => plan.code === PlanType.FREE, [plan.code]);
  const isCurrentPlan = useMemo(
    () => plan.code === currentPlanCode || plan.annualCode === currentPlanCode,
    [plan.code, plan.annualCode, currentPlanCode]
  );

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setIsBuyNow(false);
  }, []);

  const handleUpgrade = useCallback(
    (code: string, hasFreeTrialPeriod: boolean) => {
      startUpgrade({
        code,
        hasFreeTrialPeriod: hasFreeTrialPeriod && !isBuyNow,
      });
    },
    [startUpgrade, isBuyNow]
  );

  const menuItems = useMemo(
    () => [
      {
        component: (
          <CustomBox display="flex" gap={1} alignItems="center">
            <CustomTypography>
              <FormattedMessage id="pay.annually" />
            </CustomTypography>
            <STChip label="Save 20%" />
          </CustomBox>
        ),
        onClick: () => handleUpgrade(plan.annualCode, plan.hasFreeTrialPeriod),
      },
      {
        intlId: 'pay.monthly',
        onClick: () => handleUpgrade(plan.code, plan.hasFreeTrialPeriod),
      },
    ],
    [plan, handleUpgrade]
  );

  return {
    handleOpenMenu,
    handleCloseMenu,
    menuItems,
    isPro,
    isTeamStarterPlan,
    isFreePlan,
    isCurrentPlan,
    anchorEl,
    loadingUpdateUrl,
    setIsBuyNow,
  };
};

export default usePricingPlanItem;
