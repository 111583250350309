import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

const BOT_USE_CASE_LABEL = graphql(
  `
    query BotsUseCaseLabel {
      botsUseCaseLabel {
        id
        quantityLabel
        useCaseLabelFR
        useCaseLabel
        swiftaskTokenCoef
      }
    }
  `
);

const useGetBotsUseCaseLabel = () => {
  const { data, error, loading } = useQuery(BOT_USE_CASE_LABEL);
  return { data, error, loading };
};

export default useGetBotsUseCaseLabel;
