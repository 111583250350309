import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { graphql } from '../../../../gql';
import useDisplayMessage from '../../GlobalMessage/useDisplayMessage';
import { GET_DATASOURCE_SESSION } from '../../../Chat/hooks/useGetChatSessionDefaultBot';

const TOOGLE_FAVORITE_BOT = graphql(`
  mutation ToggleFavoriteBot($botId: Float!) {
    toggleFavoriteBot(botId: $botId)
  }
`);

const useToogleFavoriteBot = () => {
  const { displayMessage } = useDisplayMessage();
  const params = useParams();

  const [toggleFavoriteBot, { loading: loadingToogleFavoriteBot }] =
    useMutation(TOOGLE_FAVORITE_BOT, {
      onError: () => {
        displayMessage({
          type: 'error',
          message: 'toggle.favorite.agent.error',
        });
      },
      onCompleted: (data) => {
        displayMessage({
          type: 'success',
          message: data.toggleFavoriteBot
            ? 'add.favorite.agent.success'
            : 'remove.favorite.agent.success',
        });
      },
      update: (cache, { data }) => {
        const existingCache = cache.readQuery({
          query: GET_DATASOURCE_SESSION,
          variables: {
            sessionId: +(params?.sessionId || 0),
          },
        });

        if (existingCache?.getOneTodoChatSession?.defaultBot) {
          const updatedCache = {
            ...existingCache,
            getOneTodoChatSession: {
              ...existingCache.getOneTodoChatSession,
              defaultBot: {
                ...existingCache.getOneTodoChatSession.defaultBot,
                isFavorite: data?.toggleFavoriteBot,
              },
            },
          };

          cache.writeQuery({
            query: GET_DATASOURCE_SESSION,
            data: updatedCache,
            variables: {
              sessionId: +(params?.sessionId || 0),
            },
          });
        }
      },
    });

  const handleToogleFavoriteBot = ({ botId }: { botId: number }) => {
    toggleFavoriteBot({
      variables: {
        botId,
      },
    });
  };

  return {
    handleToogleFavoriteBot,
    loadingToogleFavoriteBot,
  };
};

export default useToogleFavoriteBot;
