import React from 'react';
import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { gradientBoxStyles } from '../../../Styles/GradianStyle';

export interface STTableProps {
  columns: Array<{
    component?: React.ReactNode;
    title: string | null;
    width?: string | number;
  }>;
  rows: Array<
    Array<{
      id?: number;
      value: React.ReactNode;
    }>
  >;
  width?: string | number;
  onClickRow?(id?: number): void;
}

const gradientBorderStyles = {
  position: 'relative',
  border: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    padding: '1px',
    background:
      'linear-gradient(180deg, rgba(16, 152, 247, 0.2) 0%, rgba(255, 110, 114, 0.2) 50%, rgba(246, 174, 45, 0.2) 100%)',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none',
  },
};

const GradientTableHead = styled(TableHead)(({ theme }) => ({
  border: 'none',
  '& .MuiTableCell-head': {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    border: 'none',
  },
}));

const GradientTableRow = styled(TableRow)(({ theme }) => ({
  border: 'none',
  '&:hover': {
    backgroundColor: alpha(theme.palette.action.hover, 0.1),
  },
  transition: 'background-color 0.3s ease',
}));

const STTable: React.FC<STTableProps> = ({
  columns,
  rows,
  width,
  onClickRow,
}) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        ...(gradientBoxStyles(theme, 'BOTTOM_LEFT') as SxProps),
        padding: 0,
      }}
    >
      <Table
        sx={{ minWidth: 200, width: width, border: 'none' }}
        aria-label="simple table"
      >
        <GradientTableHead>
          <TableRow>
            {columns.map((header, index) => (
              <TableCell sx={{ pl: '24px' }} key={`header-${index}`}>
                {header.component ||
                  (header.title && intl.formatMessage({ id: header.title })) ||
                  ''}
              </TableCell>
            ))}
          </TableRow>
        </GradientTableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <GradientTableRow
              key={`row-${rowIndex}`}
              onClick={() => onClickRow && onClickRow(row[0].id)}
            >
              {row.map((cell, cellIndex) => (
                <TableCell
                  sx={{
                    ...gradientBorderStyles,
                    pl: '24px',
                    color: theme.palette.grey[200],
                    fontWeight: '700',
                  }}
                  key={`cell-${rowIndex}-${cellIndex}`}
                  width={columns[cellIndex].width}
                >
                  {cell.value}
                </TableCell>
              ))}
            </GradientTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default STTable;
