import React from 'react';
import { STTableProps } from '../../../../../common/STTable/STTable';
import STTableGradient from '../../../../../common/STTableGradiant/STTableGradiant';

interface TokenUsageTableProps {
  columns: STTableProps['columns'];
  rows: STTableProps['rows'];
}

const TokenUsageTable: React.FC<TokenUsageTableProps> = ({ columns, rows }) => {
  return <STTableGradient columns={columns} rows={rows} width="100%" />;
};

export default TokenUsageTable;
