import React, { FC, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import CustomBox from '../CustomBox/CustomBox';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
interface IMenuItem {
  intlId?: string;
  label?: string;
  onClick?: () => void;
  component?: React.ReactNode;
}

interface IGradientProps {
  menuItems: IMenuItem[];
  title?: string;
  titleIntlId?: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

const GradientPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .MuiPaper-root': {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: 'inherit',
      padding: '1px',
      background: theme.palette.customColor.gradiantColor,
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      pointerEvents: 'none',
    },
  },
  '& .menu-title': {
    padding: theme.spacing(1, 0),
    fontWeight: 'bold',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const STGradiantMenu: FC<IGradientProps> = ({
  menuItems,
  title,
  titleIntlId,
  anchorEl,
  open,
  onClose,
}) => {
  const intl = useIntl();
  const popperRef = useRef<HTMLDivElement>(null);

  const menuTitle = titleIntlId
    ? intl.formatMessage({ id: titleIntlId })
    : title;

  return (
    <GradientPopper open={open} anchorEl={anchorEl} ref={popperRef}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper>
          {menuTitle && (
            <CustomBox
              display="flex"
              className="menu-title"
              alignItems="center"
              gap={5}
              width={'100%'}
            >
              <Typography variant="subtitle1">{menuTitle}</Typography>
              <CustomIconButton
                onClick={onClose}
                sx={{ ml: 'auto' }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </CustomIconButton>
            </CustomBox>
          )}
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  if (item.onClick) item.onClick();
                  onClose();
                }}
              >
                {item.component ?? (
                  <ListItemText
                    sx={{ fontWeight: '700' }}
                    primary={
                      item.intlId
                        ? intl.formatMessage({ id: item.intlId })
                        : item.label
                    }
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </GradientPopper>
  );
};

export default React.memo(STGradiantMenu);
