import { useMemo } from 'react';
import useGetBot from '../../../../../Hooks/useGetBot';
import useGetSubscriptionContext from '../../../../Context/SubscriptionContext/useGetSubscriptionContext';
import { useTheme } from '@mui/material';
interface useBotLimitReachedCTAProps {
  botId: number;
}
const useBotLimitReachedCTA = ({ botId }: useBotLimitReachedCTAProps) => {
  const { data, loading } = useGetBot({ botId });
  const { planCode } = useGetSubscriptionContext();
  const theme = useTheme();

  const remainingExtraCredit = useMemo(() => {
    return data?.bot?.currentUserCredit?.remainingExtraCredit || 0;
  }, [data]);

  const isBotPartOfSubscription = data?.bot?.isBotPartOfSubscription;
  const descriptionMessageId = isBotPartOfSubscription
    ? 'limit.reached.purchasecreditcta'
    : 'limit.reached.notpartofsubscription';

  return {
    data,
    loading,
    planCode,
    theme,
    remainingExtraCredit,
    descriptionMessageId,
  };
};
export default useBotLimitReachedCTA;
