import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql';
import { useEffect, useCallback } from 'react';
// import { SubscriptionPlanGroupType } from '../components/UpgradeButton/PricingPlansDialog/PricingPlansContent/usePricingPlansContent';
import { InputMaybe, SubscriptionPlanGroupType } from '../gql/graphql';

const GET_SUBSCRIPTION_PLANS_GROUPED = graphql(
  `
    query GetSubscriptionPlansGrouped($planType: SubscriptionPlanGroupType) {
      getSubscriptionPlansGrouped(planType: $planType) {
        id
        code
        description
        name
        price
        currency
        maxUser
        isEnterprisePlan
        landingPageCTA
        landingPageDisplayOrder
        landingPagePrice
        priceToCompare
        subscriptionPlanDetails
        annualCode
        hasFreeTrialPeriod
        trialPeriodCTA
        trialPeriodCTAFR
        trialPeriodDays
      }
    }
  `
);

const useGetSubscriptionPlansGrouped = (
  initialPlanType: InputMaybe<SubscriptionPlanGroupType> = SubscriptionPlanGroupType.Solo
) => {
  const [getSubscriptionPlans, { loading, data, error }] = useLazyQuery(
    GET_SUBSCRIPTION_PLANS_GROUPED
  );

  const fetchSubscriptionPlans = useCallback(
    (planType = initialPlanType) => {
      getSubscriptionPlans({ variables: { planType } });
    },
    [getSubscriptionPlans, initialPlanType]
  );

  useEffect(() => {
    fetchSubscriptionPlans(initialPlanType);
  }, [initialPlanType, fetchSubscriptionPlans]);

  return { fetchSubscriptionPlans, loading, data, error };
};

export default useGetSubscriptionPlansGrouped;
