import React from 'react';
import { BoxProps, SxProps, useTheme } from '@mui/material';
import { AutoAwesome, ShoppingCart, ArrowForward } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import CanceledPlans from '../../../CanceledPlans';
import STGradiantButton from '../../../../common/STGradiantButton/STGradiantButton';
import STGradiantChip from '../../../../common/STGradiantChip/STGradiantChip';
import PlanDetails from './PlanDetails/PlanDetails';
import PriceDisplay from './PriceDisplay/PriceDisplay';
import STGradiantMenu from '../../../../common/STGradiantMenu/STGradiantMenu';
import { gradientBoxStyleWithHoverAnimation } from '../../../../../Styles/GradianStyle';
import usePricingPlanItem from './usePricingPlanItem';
import config from '../../../../../config/config';
import { SubscriptionPlanType } from '../PricingPlansContent';
import { SubscriptionPlanGroupType } from '../../../../../gql/graphql';

interface PricingPlanItemProps extends BoxProps {
  plan: SubscriptionPlanType;
  currentPlanCode: string;
  index: number;
  groupPlan: SubscriptionPlanGroupType;
}

const PricingPlanItem: React.FC<PricingPlanItemProps> = ({
  plan,
  currentPlanCode,
  index,
  groupPlan,
  ...rest
}) => {
  const theme = useTheme();
  const {
    handleOpenMenu,
    menuItems,
    isPro,
    anchorEl,
    handleCloseMenu,
    isTeamStarterPlan,
    isFreePlan,
    isCurrentPlan,
    loadingUpdateUrl,
    setIsBuyNow,
  } = usePricingPlanItem({ plan, currentPlanCode });

  const getGradientDirection = () => {
    if (index === 0) return 'BOTTOM_LEFT';
    if (index === 1) return 'TOP_LEFT';
    return 'BOTTOM_RIGHT';
  };

  const renderTrialPeriodCTA = () => {
    if (plan.hasFreeTrialPeriod) {
      return (
        <CustomBox
          display="flex"
          justifyContent="center"
          zIndex={config.zIndex.NIVEAU_1}
          onClick={(e: any) => {
            handleOpenMenu(e);
            setIsBuyNow(true);
          }}
          width="100%"
          disable={isCurrentPlan}
        >
          <CustomBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateX(-4px)',
                '& .arrow-icon': {
                  opacity: 1,
                },
              },
            }}
            gap={1}
          >
            <ShoppingCart fontSize="small" />
            <CustomTypography fontWeight="700" variant="h6">
              <FormattedMessage id="buy.credit.now" />
            </CustomTypography>
            <ArrowForward
              fontSize="small"
              sx={{ opacity: 0, transition: 'all 0.3s ease' }}
              className="arrow-icon"
            />
          </CustomBox>
        </CustomBox>
      );
    }
    return null;
  };

  return (
    <CustomBox
      {...rest}
      sx={{
        ...(gradientBoxStyleWithHoverAnimation(
          theme,
          getGradientDirection()
        ) as SxProps),
      }}
    >
      <CustomBox display="flex" gap={1} width="100%" alignItems="center">
        <CustomTypography fontWeight={900} fontSize="16px" lineHeight="26px">
          {plan.name}
        </CustomTypography>
        {!isFreePlan && (
          <AutoAwesome
            sx={{ fontSize: '16px', fontWeight: 900 }}
            color="inherit"
          />
        )}
        {(isPro || isTeamStarterPlan) && (
          <STGradiantChip
            disabled
            variant="outlined"
            labelId="Popular"
            sx={{
              fontSize: '12px',
              fontWeight: '700',
              height: '17px',
              ml: 'auto',
              textTransform: 'uppercase',
            }}
          />
        )}
      </CustomBox>

      <CustomBox
        component="div"
        sx={{
          fontWeight: '700',
          display: 'flex',
          width: '100%',
          minHeight: 40,
          gap: 1,
        }}
      >
        <CustomTypography
          variant="h6"
          sx={{ fontSize: 32, lineHeight: 1, fontWeight: 900 }}
        >
          €{plan.priceToCompare}
        </CustomTypography>
        <PriceDisplay plan={plan} groupPlan={groupPlan} />
      </CustomBox>

      {!isFreePlan && (
        <>
          <STGradiantButton
            fullWidth
            onClick={handleOpenMenu}
            disabled={isCurrentPlan}
            size="small"
            labelId={
              isCurrentPlan
                ? 'current.sub.plan'
                : plan.hasFreeTrialPeriod
                ? `${plan.trialPeriodDays} ${plan.trialPeriodCTA} `
                : plan.landingPageCTA || 'Get started'
            }
            isLoading={loadingUpdateUrl}
            sx={{ height: '35px', zIndex: config.zIndex.NIVEAU_1 }}
            variant={index === 1 ? 'contained' : 'outlined'}
          />
          <STGradiantMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            menuItems={menuItems}
          />
          {renderTrialPeriodCTA()}
          <PlanDetails plan={plan} />
        </>
      )}

      {!isFreePlan && isCurrentPlan && (
        <CustomBox zIndex={config.zIndex.NIVEAU_2}>
          <CanceledPlans />
        </CustomBox>
      )}
    </CustomBox>
  );
};

export default PricingPlanItem;
