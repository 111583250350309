import { useNavigate, useParams } from 'react-router-dom';
import STGradiantButton from '../../../../common/STGradiantButton/STGradiantButton';
import ShareIcon from '@mui/icons-material/Share';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import { SxProps, useTheme } from '@mui/material';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { gradientBoxStyles } from '../../../../../Styles/GradianStyle';
const ShareCreditButton = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleShareCredit = () => {
    navigate(`../../${workspaceId}/share_credit`);
  };
  return (
    <CustomBox
      sx={{
        ...(gradientBoxStyles(theme) as SxProps),
        width: '100%',
        flexDirection: ['column', 'row'],
        alignItems: 'center',
        gap: [1, 0],
      }}
    >
      <CustomTypography
        width={['100%', '80%']}
        sx={{
          fontWeight: '700',
          color: theme.palette.grey[200],
        }}
      >
        <FormattedMessage id={'share.credit.description'} />
      </CustomTypography>
      <STGradiantButton
        onClick={handleShareCredit}
        variant="outlined"
        size="small"
        sx={{ height: 35, ml: 'auto' }}
        startIcon={<ShareIcon fontSize="small" />}
        labelId="button.share.credit"
      />
    </CustomBox>
  );
};

export default ShareCreditButton;
