import Divider from '@mui/material/Divider';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import ReferralCreditsPopup from '../../../../Setting/Account/ReferralContent/ReferralCreditsPopup/ReferralCreditsPopup';
import PurchaseCreditButton from '../../../../Setting/BotsCredit/PurchaseCreditButton/PurchaseCreditButton';
import UpgradeWorkspace from '../../../../Workspace/UpgradeWorkspace/UpgradeWorkspace';
import { formatNumber } from '../../../../../utils/numberUtil';
import { SUBSCRIPTION_LEVEL } from '../../../../../constants/subscription';
import useBotLimitReachedCTA from './useBotLimitReachedCTA';

interface BotLimitReachedCTAProps {
  botId: number;
  isDisplayDescription?: boolean;
}

const BotLimitReachedCTA: FC<BotLimitReachedCTAProps> = ({
  botId,
  isDisplayDescription = true,
}) => {
  const {
    data,
    loading,
    planCode,
    theme,
    remainingExtraCredit,
    descriptionMessageId,
  } = useBotLimitReachedCTA({ botId });
  if (loading || !data) return null;
  if (!data?.bot) return null;
  if (remainingExtraCredit > 0) return null;

  return (
    <CustomBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70%',
      }}
    >
      <CustomTypography
        variant="h2"
        my={theme.spacing(1)}
        fontWeight="700"
        sx={{
          background: 'transparent',
          color: '#F6AE2D',
          backgroundImage: theme.palette.customColor.gradiantColor,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        <FormattedMessage id={'upgrade.credit.title'} />
      </CustomTypography>
      {planCode === SUBSCRIPTION_LEVEL.FREE && (
        <CustomBox
          sx={{
            mt: (theme) => theme.spacing(1),
            backgroundColor: theme.palette.customColor.messageBackground,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(3),
            width: '100%',
          }}
        >
          <CustomBox
            sx={{
              mb: (theme) => theme.spacing(2),
            }}
          >
            <CustomTypography variant="h5" fontWeight={'700'}>
              <FormattedMessage
                id="limit.reached.upgradeplancta"
                values={{
                  botPrice: data?.bot?.creditUnitPrice,
                  botName: data?.bot?.name,
                  priceQuantity: formatNumber(
                    data?.bot?.creditAmountPerUnitPrice || 0
                  ),
                  quantityLabel: data?.bot?.quantityLabel,
                }}
              />
            </CustomTypography>
          </CustomBox>

          <CustomBox
            sx={{
              // Align button to the center
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <UpgradeWorkspace
              alwaysDisplayUpgradeButton={true}
              buttonHeight={40}
              buttonWidth={300}
            />
          </CustomBox>
        </CustomBox>
      )}
      <CustomBox
        my={theme.spacing(2)}
        sx={{
          backgroundColor: theme.palette.customColor.messageBackground,
          borderRadius: theme.spacing(1),
          padding: theme.spacing(3),
          width: '100%',
        }}
      >
        <CustomBox
          marginInline={'auto'}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
        >
          {isDisplayDescription && (
            <CustomBox>
              <CustomTypography variant="h5" fontWeight={'700'}>
                <FormattedMessage
                  id={descriptionMessageId}
                  values={{
                    botPrice: data?.bot?.creditUnitPrice,
                    botName: data?.bot?.name,
                    priceQuantity: formatNumber(
                      data?.bot?.creditAmountPerUnitPrice || 0
                    ),
                    quantityLabel: data?.bot?.quantityLabelFR,
                  }}
                />
              </CustomTypography>
            </CustomBox>
          )}

          <CustomBox
            sx={{
              // Align button to the center
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: (theme) => theme.spacing(2),
            }}
          >
            <PurchaseCreditButton
              //stripeLink={data?.bot?.stripePaymentLink}
              //currentUserEmail={dataMe?.me?.email}
              buttonHeight={40}
              sx={{
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                width: 300,
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                  boxShadow: (theme) =>
                    `0 0 10px ${theme.palette.common.black}80`,
                },
              }}
              // botName={data.bot?.name}
              //creditPrice={data.bot?.creditUnitPrice || 0}
            />
          </CustomBox>
          <Divider
            sx={{
              mt: theme.spacing(2),
              marginInline: 'auto',
              color: (theme) => theme.palette.primary.main,
              width: '30%',
            }}
          >
            <CustomTypography variant="h5">
              <FormattedMessage id="limit.reached.referralcta" />
            </CustomTypography>
          </Divider>

          <CustomBox
            sx={{
              mt: theme.spacing(1),
              marginInline: 'auto',
            }}
          >
            <ReferralCreditsPopup />
          </CustomBox>
        </CustomBox>
      </CustomBox>
    </CustomBox>
  );
};

export default BotLimitReachedCTA;
