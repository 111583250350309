import MoreHoriz from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonTaskAction from '../../MyDay/FormAddTask/ButtonTaskAction/ButtonTaskAction';
import STMenu from '../../common/STMenu/STMenu';
import CustomBox from '../../common/CustomBox/CustomBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import useConfirmDialog from '../../../Hooks/useConfirmDialog';
import useDeleteCustomBot from '../../../Hooks/useDeleteCustomBot';

const BotAction = ({
  botId,

  openEditDialog,
}: {
  botId: number;
  openEditDialog?(botId: number): void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { displayConfirmDialog } = useConfirmDialog();
  const { handleDeleteCustomBot } = useDeleteCustomBot();
  const open = Boolean(anchorEl);

  const intl = useIntl();
  const handleToggleDialog = () => {
    setAnchorEl(null);
    if (openEditDialog) openEditDialog(botId);
  };
  const handelDeleteBot = () => {
    displayConfirmDialog({
      message: 'delete.bot.alert.description',
      onConfirm: () => {
        handleDeleteCustomBot(botId);
      },
    });
  };
  const menuItems = [
    {
      component: (
        <CustomBox display={'flex'} gap={1}>
          <ModeEditIcon fontSize="small" />
          <CustomTypography>
            <FormattedMessage id="update.bot.title" />
          </CustomTypography>
        </CustomBox>
      ),
      onClick: () => {
        handleToggleDialog();
      },
    },
    {
      component: (
        <CustomBox display={'flex'} gap={1}>
          <DeleteIcon fontSize="small" />
          <CustomTypography>
            <FormattedMessage id="delete.bot.title" />
          </CustomTypography>
        </CustomBox>
      ),
      onClick: () => {
        handelDeleteBot();
      },
    },
  ];
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <CustomBox>
      <ButtonTaskAction
        handleaAction={handleOpenMenu}
        labelTooltip={intl.formatMessage({
          id: 'message.actionMessage.more',
        })}
        icon={<MoreHoriz style={{ marginRight: '2px' }} />}
      />

      <STMenu
        menuItems={menuItems}
        anchorEl={anchorEl}
        open={open}
        handleClose={() => {
          setAnchorEl(null);
        }}
      />
    </CustomBox>
  );
};
export default BotAction;
