import FavoriteIcon from '@mui/icons-material/Favorite';
import CustomIconButton from '../../CustomIconButton/CustomIconButton';
import useToogleFavoriteBot from './useToogleFavoriteBot';
import CustomTooltip from '../../CustomToolTip/CustomToolTip';

const FavoriteBotMark = ({
  botId,
  isFavorite,
}: {
  botId: number;
  isFavorite?: boolean;
}) => {
  const { handleToogleFavoriteBot, loadingToogleFavoriteBot } =
    useToogleFavoriteBot();

  return (
    <CustomTooltip
      intlId={
        isFavorite ? 'remove.agent.from.favorites' : 'add.agent.to.favorites'
      }
    >
      <CustomIconButton
        size="small"
        onClick={() => handleToogleFavoriteBot({ botId })}
        disabled={loadingToogleFavoriteBot}
      >
        <FavoriteIcon
          fontSize="small"
          color={isFavorite ? 'secondary' : 'disabled'}
        />
      </CustomIconButton>
    </CustomTooltip>
  );
};

export default FavoriteBotMark;
