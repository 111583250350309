import { useTheme } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import PricingPlanItem from './PricingPlanItem/PricingPlanItem';
import {
  SubscriptionPlan,
  SubscriptionPlanGroupType,
} from '../../../../gql/graphql';
import usePricingPlansContent from './usePricingPlansContent';
import SwiftaskCreditPurshase from '../../../SwiftaskToken/SwiftaskCreditPurshase/SwiftaskCreditPurshase';
import ShareCreditButton from './ShareCreditButton/ShareCreditButton';
import STGradiantToggle from '../../../common/STGradiantToggle/STGradiantToggle';
import TokenUsage from './TokenUsage/TokenUsage';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import PricingLoader from './PricingLoader/PricingLoader';
export type SubscriptionPlanType = SubscriptionPlan & {
  annualCode: string;
  hasFreeTrialPeriod: boolean;
};
const PricingPlansContent = () => {
  const {
    groupPlan,
    filteredPlans,
    handleChange,
    TOGGLE_DATA,
    currentPlanCode,
    loading,
  } = usePricingPlansContent();
  const theme = useTheme();
  return (
    <CustomBox display={'flex'} flexDirection={'column'} width={'100%'}>
      <CustomBox marginInline={'auto'} py={theme.spacing(2)}>
        <STGradiantToggle
          value={groupPlan}
          exclusive
          onChange={handleChange}
          size="small"
          toggleData={TOGGLE_DATA as any}
        />
      </CustomBox>
      {loading ? (
        <PricingLoader />
      ) : groupPlan === SubscriptionPlanGroupType.Credit ? (
        <>
          <CustomTypography
            mb={2}
            fontWeight={'700'}
            variant="h3"
            textAlign={'center'}
          >
            <FormattedMessage id="plan.credit.title" />
          </CustomTypography>
          <SwiftaskCreditPurshase />
        </>
      ) : (
        <CustomBox display={'flex'} width={'100%'} gap={1} flexWrap={'wrap'}>
          {filteredPlans.length > 0
            ? (filteredPlans || [])?.map((plan: any, index: number) => (
                <PricingPlanItem
                  key={plan?.id}
                  index={index}
                  groupPlan={groupPlan}
                  currentPlanCode={currentPlanCode as string}
                  plan={plan as SubscriptionPlanType}
                  width={['100%', `${100 / filteredPlans?.length - 1}%`]}
                  padding={theme.spacing(1)}
                />
              ))
            : null}
        </CustomBox>
      )}
      <CustomBox>
        <TokenUsage />
      </CustomBox>
      <CustomBox my={2} display={'flex'} justifyContent={'flex-end'}>
        <ShareCreditButton />
      </CustomBox>
    </CustomBox>
  );
};

export default PricingPlansContent;
