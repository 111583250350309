import { useTheme } from '@mui/material';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SubscriptionPlanType } from '../../PricingPlansContent';

interface PlanDetailsProps {
  plan: SubscriptionPlanType;
}
const PlanDetails: React.FC<PlanDetailsProps> = ({ plan }) => {
  const theme = useTheme();
  return (
    <CustomBox>
      <CustomTypography my={theme.spacing(1)} variant="h4">
        {plan.subscriptionPlanDetails?.title}
      </CustomTypography>
      {plan.subscriptionPlanDetails?.items?.map((item: any, index: number) => (
        <CustomBox
          key={index}
          display="flex"
          gap={1}
          lineHeight={'25px'}
          alignItems={'center'}
        >
          <CheckCircleOutlineIcon fontSize="small" sx={{ fontSize: '14px' }} />
          <CustomTypography
            sx={
              index < 3
                ? {
                    fontSize: '11px',
                    background: 'transparent',
                    backgroundImage: theme.palette.customColor.gradiantColor,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }
                : { fontSize: '11px' }
            }
          >
            {item}
          </CustomTypography>
        </CustomBox>
      ))}
    </CustomBox>
  );
};
export default PlanDetails;
