import { FC } from 'react';
// Generic radio group component
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../CustomBox/CustomBox';

interface IProps {
  options: Array<{
    label: string;
    value: string;
    subLabel?: string;
  }>;
  value: string | number;
  onChange: (e: any) => void;
  title: string;
  name: string;
  row?: boolean;
}

const STRadioGroup: FC<IProps> = ({
  value,
  onChange,
  options,
  title,
  name,
  row = false,
}) => {
  return (
    <FormControl>
      <FormLabel>
        <CustomTypography variant="body2">
          <FormattedMessage id={title} />
        </CustomTypography>
      </FormLabel>
      <RadioGroup row={row} name={name} onChange={onChange} value={value}>
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            sx={{
              mb: 1,
            }}
            label={
              <CustomBox>
                <CustomTypography variant="body2">
                  {option.label}
                </CustomTypography>
                {option.subLabel && (
                  <CustomTypography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                    }}
                  >
                    {option.subLabel}
                  </CustomTypography>
                )}
              </CustomBox>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default STRadioGroup;
